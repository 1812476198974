
import {
  defineComponent,
  onMounted,
  computed,
  reactive,
  onUnmounted,
  watch,
  onBeforeMount,
  onBeforeUnmount
} from "vue";
import { useAbility } from "@casl/vue";
import { useRouter } from "vue-router";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import { useStore } from "vuex";

type inputs = {
  typeOfWork: string;
  workerType: string;
  safetyReqs: string;
  prefDresscode: string;
  waterPolicy: string;
  foodPolicy: string;
};

export default defineComponent({
  name: "about-company-tab",
  components: {
    LoadingSpinner
  },
  setup() {
    const store = useStore();
    const company = computed(
      () => store.getters["ClientsModule/getFetchedSingle"]
    );
    const loading = computed<boolean>(
      () => store.getters["ClientsModule/loading"]
    );
    const isUpdatingCompany = computed<boolean>(
      () => store.getters["ClientsModule/isUpdatingCompany"]
    );
    const inputData = reactive<inputs>({
      typeOfWork: "",
      workerType: "",
      safetyReqs: "",
      prefDresscode: "",
      waterPolicy: "",
      foodPolicy: ""
    });

    function ifNull(entry) {
      if (entry !== null) return entry;
      else return "";
    }

    function populateTabsFromData(JobData) {
      inputData.typeOfWork = ifNull(JobData.type_of_work);
      inputData.workerType = ifNull(JobData.value_workers);
      inputData.safetyReqs = ifNull(JobData.safety_requirements);
      inputData.prefDresscode = ifNull(JobData.dresscode);
      inputData.waterPolicy = ifNull(JobData.water_policy);
      inputData.foodPolicy = ifNull(JobData.food_policy);
    }

    onMounted(() => {
      if (!useAbility().can("can_edit_own_company_data", "all")) {
        useRouter().push("/");
      }
      store
        .dispatch(
          "ClientsModule/fetchSingle",
          store.getters.currentUser.company_id
        )
        .then(JobData => {
          populateTabsFromData(JobData);
        });
    });

    onBeforeUnmount(() => {
      store.dispatch("ClientsModule/resetState");
    });

    function saveData() {
      store.dispatch("ClientsModule/saveAboutCompanyData", inputData);
    }

    return {
      loading,
      company,
      inputData,
      saveData,
      isUpdatingCompany
    };
  }
});
